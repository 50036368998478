.page-title {
    text-align: center;
    max-width: 650px;
    margin: 150px auto 100px;

    h2 {
        font-size: 40px;
        font-weight: 700;
        margin-bottom: 10px;
    }
}

// Small Device Responsive
@media (max-width: 575px) {
    .page-title {
        margin: 120px auto 50px;  // Tăng margin-top để kéo tiêu đề xuống

        h2 {
            font-size: 20px;
            margin-top: 30px;  // Tăng margin-top cho tiêu đề để kéo nó xuống
            margin-bottom: 15px;  // Điều chỉnh khoảng cách dưới tiêu đề
        }
    }
}
