@import '../../sass/colors.scss';

footer {
    background: $color-black;

    .footer-logo {
        margin-bottom: 20px; // Giảm margin để logo không bị cách xa quá
        max-width: 150px;  // Giảm kích thước tối đa của logo
        width: 100%; // Đảm bảo logo co giãn theo kích thước của footer

        img {
            max-width: 100%; // Đảm bảo ảnh logo không vượt quá 100% chiều rộng của container
            height: auto; // Giữ tỷ lệ cho ảnh
        }
    }

    .social-logo {
        p {
            font-size: 12px;
            text-transform: uppercase;
            font-weight: 600;
        }

        ul {
            margin-bottom: 0;
            padding: 0;

            li {
                list-style: none;
                display: inline-block;

                a {
                    color: $color-white;
                    margin-right: 10px;
                }
            }
        }
    }

    .footer-link {
        p {
            text-transform: uppercase;
        }

        ul {
            padding: 0;
            margin: 0;

            li {
                list-style: none;
                margin-bottom: 10px;

                a {
                    text-decoration: none;
                    color: $color-white;
                    font-size: 15px;
                }
            }
        }
    }

    .footer-contact {
        p {
            text-transform: uppercase;
        }
    }

    .contact-list {
        display: flex;
        align-items: center;
        margin-bottom: 40px;
        margin-top: 30px;
        flex-wrap: wrap; // Cho phép các mục trong danh sách liên hệ bọc lại khi cần

        .contact-icon {
            background: $color-white;
            width: 50px;
            height: 50px;
            line-height: 50px;
            border-radius: 20px;
            position: relative;
            z-index: 0;
            text-align: center;

            &::before {
                position: absolute;
                content: '';
                width: 70px;
                height: 70px;
                background: $color-white;
                top: -10px;
                left: -10px;
                z-index: -1;
                opacity: 0.3;
                border-radius: 20px;
            }

            img {
                max-width: 50px;
            }
        }

        .contact-text {
            margin-left: 30px;
            max-width: 70%; // Giới hạn chiều rộng tối đa của text để giữ icon thẳng hàng

            p {
                font-size: 12px;
                margin-bottom: 5px;
                text-transform: capitalize;
                font-weight: 500;
            }

            h5 {
                color: $color-white;
                font-size: 16px;
            }
        }
    }

    .copyright-area {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 50px;

        .copy-text {
            p {
                margin-bottom: 0;
            }
        }

        .copy-links {
            ul {
                li {
                    display: inline-block;
                    margin-left: 20px;

                    a {
                        color: $color-white;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

// Developed by
.developed-by {
    margin-top: 15px;
    font-size: 14px;
    color: $color-gray;
    text-align: center;
    display: flex;
    align-items: center; // Căn chỉnh icon và text
    gap: 8px; // Khoảng cách giữa icon và text

    .icon {
        font-size: 16px;
        color: $color-white; // Màu giống với màu của footer
    }
}

// Mobile Responsive
@media (max-width: 575px) {
    footer {
        .footer-logo {
            max-width: 120px;  // Giảm kích thước logo thêm trên màn hình nhỏ
            margin-bottom: 15px;
        }

        .contact-list {
            margin-bottom: 10px;
            flex-direction: column; // Đặt các icon và text thành cột để dễ dàng căn chỉnh
            align-items: flex-start;

            .contact-icon {
                width: 45px;
                height: 45px;
                line-height: 45px;
                border-radius: 10px;

                &::before {
                    width: 60px;
                    height: 60px;
                    top: -8px;
                    left: -8px;
                    border-radius: 15px;
                }
            }

            .contact-text {
                margin-left: 0; // Xóa margin khi các mục được xếp theo chiều dọc
                margin-top: 10px; // Thêm khoảng cách giữa icon và text
                h5 {
                    font-size: 14px; // Giảm kích thước font cho màn hình nhỏ
                }
            }
        }
    }
}
