@import './../../sass/fonts.scss';
@import './../../sass/colors.scss';

.main-nav {
    padding: 10px 0;  // Giảm padding trên và dưới của navbar (giúp ngắn lại)
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99999;
    background: $color-white;

    .navbar {
        display: flex;
        justify-content: space-between;
        align-items: center;  // Căn giữa theo chiều dọc
        margin: 0;
        padding: 0;
        width: 100%;  // Đảm bảo navbar chiếm full chiều rộng

        .navbar-brand {
            img {
                max-width: 120px;  // Giảm kích thước logo để navbar ngắn lại
                height: auto;
            }
        }

        .navbar-nav {
            display: flex;
            padding: 0;
            margin: 0;
            flex-wrap: nowrap;

            .nav-item {
                .nav-link {
                    color: $color-black;
                    font-size: 14px;  // Font size vừa phải
                    font-weight: 600;
                    padding: 8px 10px;  // Giảm padding của các mục menu
                    margin: 0 10px;  // Giảm khoảng cách giữa các mục menu
                    &:hover {
                        color: $color-blue;
                    }
                }
            }
        }
    }
}

// Mobile Responsive
@media (max-width: 575px) {
    .main-nav {
        padding: 4px 0;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

        .navbar {
            flex-direction: column;  // Đặt navbar thành cột trên mobile
            justify-content: flex-start;

            .navbar-brand {
                img {
                    max-width: 70px;  // Giảm kích thước logo cho mobile
                }
            }

            .navbar-nav {
                flex-direction: column;  // Mỗi menu item sẽ nằm trên một hàng
                align-items: flex-start;
                width: 100%; // Đảm bảo chiếm hết chiều rộng

                .nav-item {
                    width: 100%; // Đảm bảo các mục menu rộng hết màn hình

                    .dropdown-toggle {
                        padding-right: 30px; // Thêm khoảng cách cho nút dropdown
                    }

                    .dropdown-menu {
                        width: 100%;  // Chiều rộng của menu dropdown
                        position: relative;
                        left: 0;
                        display: none; // Mặc định ẩn dropdown
                    }

                    // Khi dropdown được mở
                    &.open {
                        .dropdown-menu {
                            display: block; // Hiển thị dropdown khi mở
                        }
                    }
                }
            }
        }
    }
}

// Desktop View
@media (min-width: 576px) and (max-width: 991px) {
    .main-nav {
        padding: 10px 0;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

        .navbar {
            .navbar-nav {
                .nav-item {
                    .nav-link {
                        margin: 8px 12px;  // Điều chỉnh margin cho các item
                    }
                }
            }
        }
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .main-nav {
        padding: 15px 0;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

        .navbar {
            .navbar-nav {
                .nav-item {
                    .nav-link {
                        margin: 10px 15px;
                    }
                }
            }
        }
    }
}
