@import '../../sass/colors.scss';

.emergency-section {
    padding-top: 100px;
    padding-bottom: 150px;

    .emergency-img {
        text-align: right;
        position: relative;
        z-index: 1;
        margin-bottom: 30px;

        img {
            max-width: 100%;
            height: auto; /* Maintain aspect ratio */
            border-radius: 20px; /* Rounded corners */
            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
            object-fit: cover; /* Ensures the image scales nicely */
        }

        &::before {
            position: absolute;
            content: "";
            width: 45%;
            height: 90%;
            top: 5%;
            left: 5%;
            background: #DBEFFA;
            z-index: -1;
            border-radius: 20px;
        }
    }

    .emergency-text {
        max-width: 700px;
        margin-bottom: 30px;
        padding-left: 20px; /* Add spacing on the left */
        
        .section-title {
            margin-bottom: 30px;

            h2 {
                font-size: 36px; /* Adjust title size */
                line-height: 1.4; /* Improve readability */
                margin-bottom: 15px; /* Add spacing below title */
            }

            p {
                font-size: 18px;
                margin-bottom: 20px; /* Add spacing below description */
                line-height: 1.6; /* Improve text readability */
            }
        }

        .theme-btn {
            margin-top: 40px;

            a {
                display: inline-block;
                padding: 10px 20px;
                background-color: #4A90E2;
                color: #fff;
                border-radius: 5px;
                font-weight: bold;
                text-decoration: none;
                transition: background-color 0.3s ease;

                &:hover {
                    background-color: #357ABD;
                }
            }
        }
    }
}

/* Mobile Responsive */
@media (max-width: 575px) {
    .emergency-section {
        padding-top: 50px;
        padding-bottom: 50px;

        .emergency-img {
            text-align: center;

            img {
                max-width: 100%;
                border-radius: 15px;
            }

            &::before {
                display: none;
            }
        }

        .emergency-text {
            padding-left: 0; /* Remove extra spacing for smaller devices */
            
            .section-title {
                h2 {
                    font-size: 24px;
                    margin-bottom: 10px;
                }

                p {
                    font-size: 16px;
                    margin-bottom: 15px;
                }
            }

            .theme-btn {
                margin-top: 25px;
            }
        }
    }
}

/* Medium Devices Responsive */
@media (min-width: 576px) and (max-width: 991px) {
    .emergency-section {
        padding-top: 50px;
        padding-bottom: 50px;

        .emergency-img {
            text-align: center;

            img {
                max-width: 90%;
            }

            &::before {
                display: none;
            }
        }

        .emergency-text {
            padding-left: 15px;

            .section-title {
                h2 {
                    font-size: 28px;
                    margin-bottom: 15px;
                }

                p {
                    font-size: 16px;
                    margin-bottom: 20px;
                }
            }

            .theme-btn {
                margin-top: 25px;
            }
        }
    }
}

/* Large Devices */
@media (min-width: 992px) {
    .emergency-section {
        padding-top: 100px;
        padding-bottom: 150px;

        .emergency-img {
            text-align: right;

            img {
                max-width: 80%; /* Adjust width for larger screens */
            }

            &::before {
                top: 10%;
                left: 0%;
                height: 100%;
            }
        }

        .emergency-text {
            padding-left: 40px; /* Add more spacing for larger screens */
            
            .section-title {
                h2 {
                    font-size: 40px;
                    margin-bottom: 20px;
                }

                p {
                    font-size: 18px;
                    margin-bottom: 25px;
                }
            }
        }
    }
}
