.service-banner-section {
  margin-top: 130px;

  .service-banner-text {
    h2 {
      font-size: 60px;
      font-weight: 700;
      margin-bottom: 30px;
    }

    p {
      margin-bottom: 50px;
    }
  }

  .service-banner-img {
    text-align: center;
    padding-top: 80px;
    margin-bottom: -100px;

    img {
      width: 60%; /* Giảm kích thước hình ảnh */
      height: auto;
      border-radius: 20px; /* Bo góc tròn */
      object-fit: cover; /* Đảm bảo hình vừa khung */
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Thêm hiệu ứng đổ bóng */
    }
  }
}

// Mobile Responsive
@media (max-width: 575px) {
  .service-banner-section {
    margin-top: 70px;
    padding: 50px 0 50px; // Thêm khoảng padding-top cho banner

    .service-banner-text {
      text-align: center;
      padding-top: 30px; // Tăng khoảng cách trên cho tiêu đề

      h2 {
        font-size: 40px;
        margin-bottom: 20px;
      }

      p {
        margin-bottom: 20px;
      }
    }

    .service-banner-img {
      text-align: center;
      padding-top: 20px;
      margin-bottom: 0;

      img {
        width: 70%; /* Hình nhỏ hơn nữa trên mobile */
        border-radius: 15px; /* Bo góc mềm mại hơn */
      }
    }
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .service-banner-section {
    margin-top: 70px;
    padding: 30px 0 50px;

    .service-banner-text {
      text-align: center;

      h2 {
        font-size: 40px;
        margin-bottom: 20px;
      }

      p {
        margin-bottom: 20px;
      }
    }

    .service-banner-img {
      text-align: center;
      padding-top: 20px;
      margin-bottom: 0;

      img {
        width: 65%; /* Hình nhỏ hơn nữa trên tablet nhỏ */
        border-radius: 15px;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .service-banner-section {
    margin-top: 90px;
    padding: 30px 0 50px;

    .service-banner-text {
      h2 {
        font-size: 40px;
      }

      p {
        margin-bottom: 20px;
      }
    }

    .service-banner-img {
      text-align: center;
      padding-top: 20px;
      margin-bottom: 0;

      img {
        width: 60%; /* Hình nhỏ hơn nữa trên tablet lớn */
        border-radius: 15px;
      }
    }
  }
}
