@import '../../sass/colors.scss';

.single-blog {
    padding-top: 150px;
}

.single-blog-area {
    background: $color-white;
    padding: 50px 25px;
    border-radius: 12px;

    .single-blog-category {
        font-size: 12px;
        font-weight: 600;
        color: $color-blue;
        margin-bottom: 10px;
    }

    .single-blog-title {
        font-weight: 700;
        margin-bottom: 30px;
    }

    .single-blog-banner {
        margin-bottom: 30px;
        border-radius: 10px; // Bo tròn hình ảnh banner
        max-width: 100%;    // Hình ảnh chiếm tối đa chiều rộng khung
        height: auto;       // Giữ tỷ lệ khung hình
    }

    .single-blog-text {
        margin-bottom: 20px;

        .single-blog-heading {
            margin-top: 40px;
            margin-bottom: 20px;
        }

        .single-blog-subheading {
            margin-top: 30px;
            margin-bottom: 15px;
        }

        .single-blog-paragraph {
            margin-bottom: 20px; // Khoảng cách đồng nhất giữa các đoạn văn
        }

        .single-blog-list {
            margin-bottom: 20px; // Khoảng cách dưới danh sách
            li {
                margin-bottom: 10px; // Khoảng cách giữa các mục trong danh sách
            }
        }

        .single-blog-quote {
            background: $color-gray-light; // Màu nền cho blockquote
            padding: 20px 30px;            // Khoảng cách trong blockquote
            border-left: 4px solid $color-blue;
            font-style: italic;            // Kiểu chữ nghiêng
            margin-top: 30px;
            margin-bottom: 30px;
            border-radius: 5px;
            font-size: 1rem;
            color: $color-gray-dark;       // Màu chữ
        }

        .single-blog-image {
            margin-top: 30px;
            margin-bottom: 30px;
            border-radius: 8px; // Bo góc hình ảnh
            width: 100%;        // Chiếm toàn bộ chiều ngang khung nội dung
            height: auto;       // Giữ nguyên tỷ lệ hình ảnh
            display: block;     // Đảm bảo hình ảnh hiển thị như một block
        }
    }
}

.blog-sidebar {
    background: $color-white;
    padding: 50px 15px;
    border-radius: 12px;

    .sidebar-heading {
        font-size: 20px;
        margin-bottom: 15px;
        font-weight: 600;
    }

    .sidebar-category {
        ul {
            padding: 0;
            margin: 0;

            li {
                list-style: none;
                border-bottom: 1px dashed #583fbc1f;
                margin-bottom: 5px;
                padding-bottom: 5px;

                a {
                    text-decoration: none;
                    color: $color-blue;

                    &:hover {
                        color: $color-black;
                    }
                }

                &:last-child {
                    border-bottom: unset;
                }
            }
        }
    }
}

@media (max-width: 991px) {
    .single-blog-area {
        margin-bottom: 20px;
    }
}
