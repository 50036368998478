@import '../../sass/colors.scss';

.team-section {
    .section-title {
        h2 {
            max-width: inherit;
        }
    }

    .team-card {
        text-align: center;
        margin-bottom: 30px;
        
        // Sử dụng flexbox để căn chỉnh các thành phần trong thẻ team-card
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .team-img {
            margin-bottom: 15px; // Điều chỉnh khoảng cách giữa ảnh và tiêu đề
            position: relative;
            width: 250px; // Tăng kích thước hình ảnh lên
            height: 250px; // Tăng kích thước hình ảnh lên
            overflow: hidden;
            
            &::before {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                background: #DBEFFA;
                content: '';
                z-index: -1;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover; // Cắt ảnh cho vừa khung mà không bị méo
                object-position: 0% 30%; // Đưa phần đầu lên thêm chút nữa
            }
        }

        h3 {
            font-size: 18px;
            font-weight: 600;
            margin: 0; // Loại bỏ margin mặc định để không bị lệch
            padding: 0; // Loại bỏ padding để tránh bị lệch
        }
    }
}

// Small Device Responsive
@media (max-width: 575px) {
    .team-section {
        .pt-5 {
            padding-top: 0 !important;
        }

        .team-card {
            .team-img {
                width: 200px; // Giảm kích thước cho thiết bị nhỏ
                height: 200px;
                object-position: 0% 30%; // Đưa phần đầu lên cho thiết bị nhỏ
            }
        }
    }
}
