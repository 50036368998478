@import '../../sass//colors.scss';

.expert-section {
    padding: 100px 0 150px 0px;

    .section-title {
        margin-bottom: 30px;

        h2 {
            max-width: inherit;
        }
    }

    .expert-text {
        ul {
            padding: 0;
            margin: 0;

            li {
                list-style: none;
                margin-bottom: 10px;
                font-weight: 500;

                svg {
                    color: $color-blue; /* Replace with your blue or desired color */
                    margin-right: 15px;
                    top: -1px;
                    position: relative;
                }
            }
        }
    }

    .expert-img {
        text-align: left;
        margin-right: 60px;
        position: relative;
        z-index: 1;
        margin-bottom: 30px;

        img {
            max-width: 100%;
            border-radius: 15px; /* Rounded corners for the image */
            border: 3px solid #FADBE2; /* Add a subtle pink border */
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
        }

        &::before {
            position: absolute;
            content: "";
            width: 50%;
            height: 120%;
            top: -10%;
            right: -5%;
            background: #FADBE2;
            z-index: -1;
            border-radius: 20px;
        }
    }
}

// Mobile Responsive
@media (max-width: 575px) {
    .expert-section {
        padding: 50px 15px;

        .expert-text {
            ul {
                margin-bottom: 10px;

                li {
                    margin-bottom: 8px;
                    font-size: 14px;

                    svg {
                        margin-right: 5px;
                    }
                }
            }
        }

        .expert-img {
            margin-right: 0;

            img {
                border-radius: 10px; /* Reduce the border-radius for smaller screens */
                border: 2px solid #FADBE2;
            }

            &::before {
                display: none;
            }
        }
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .expert-section {
        padding: 50px 0;

        .expert-text {
            margin-bottom: 20px;
        }

        .expert-img {
            margin-right: 0px;

            img {
                border-radius: 12px; /* Slightly rounded for medium screens */
                border: 3px solid #FADBE2;
            }

            &::before {
                display: none;
            }
        }
    }
}

// Medium Devices Responsive
@media (min-width: 768px) and (max-width: 991px) {
    .expert-section {
        padding: 50px 0;

        .expert-img {
            margin-right: 20px;

            img {
                border-radius: 15px; /* Keep the default rounded corner size */
                border: 3px solid #FADBE2;
            }

            &::before {
                right: -10%;
            }
        }
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .expert-section {
        padding: 70px 0;

        .expert-img {
            margin-right: 20px;

            img {
                border-radius: 15px; /* Maintain consistent border-radius */
                border: 3px solid #FADBE2;
            }

            &::before {
                right: -10%;
            }
        }
    }
}

// Large Device Responsive
@media (min-width: 1200px) {
    .expert-section {
        .expert-img {
            &::before {
                right: -10%;
            }
        }
    }
}
