.gallery-section {
    padding-top: 100px;
    padding-bottom: 70px;

    .section-title {
        text-align: center;
        margin: 0 auto 40px;
        max-width: 1200px; /* Tăng chiều rộng khối chứa tiêu đề để không bị xuống dòng */

        h2 {
            font-size: 60px; /* Kích thước chữ lớn */
            font-weight: 700; /* Độ đậm */
            color: #2c3e50; /* Màu sắc */
            margin-bottom: 20px; /* Khoảng cách dưới tiêu đề */
            line-height: 1.2; /* Khoảng cách dòng hợp lý */
        }

        p {
            font-size: 16px; /* Kích thước đoạn mô tả */
            line-height: 1.6; /* Khoảng cách dòng đoạn mô tả */
            color: #555555; /* Màu chữ mô tả */
            margin: 0 auto; /* Căn giữa đoạn mô tả */
            max-width: 700px; /* Giới hạn chiều rộng mô tả */
        }
    }

    .gallery-grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Responsive cột */
        gap: 20px; /* Khoảng cách giữa các hình */
        justify-items: center; /* Căn giữa các phần tử */
    }

    .gallery-img {
        width: 100%; /* Chiều rộng tự động theo grid */
        position: relative;
        overflow: hidden;
        border-radius: 8px; /* Bo góc cho khung hình ảnh */
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Hiệu ứng đổ bóng */
        aspect-ratio: 4 / 3; /* Tỷ lệ khung hình chuẩn 4:3 */

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover; /* Cắt ảnh vừa khung */
        }

        &:hover {
            transform: scale(1.05); /* Hiệu ứng zoom nhẹ khi hover */
            transition: transform 0.3s ease-in-out; /* Thời gian zoom */
        }
    }
}

/* Mobile Responsive */
@media (max-width: 575px) {
    .gallery-section {
        padding-top: 50px;
        padding-bottom: 40px;

        .section-title {
            max-width: 100%; /* Đảm bảo tiêu đề co giãn trên mobile */

            h2 {
                font-size: 40px; /* Giảm kích thước chữ trên màn hình nhỏ */
                line-height: 1.2;
            }

            p {
                font-size: 14px; /* Đoạn mô tả nhỏ hơn trên mobile */
            }
        }

        .gallery-grid {
            gap: 15px; /* Giảm khoảng cách giữa các hình */
        }
    }
}

/* Tablet Responsive */
@media (min-width: 576px) and (max-width: 767px) {
    .gallery-section {
        padding-top: 60px;
        padding-bottom: 50px;

        .section-title {
            max-width: 100%; /* Đảm bảo tiêu đề co giãn trên tablet */

            h2 {
                font-size: 50px; /* Kích thước phù hợp với tablet */
            }

            p {
                font-size: 15px; /* Kích thước đoạn mô tả vừa phải */
            }
        }

        .gallery-grid {
            gap: 18px; /* Khoảng cách giữa các hình vừa phải */
        }
    }
}

/* Tablet Larger */
@media (min-width: 768px) and (max-width: 991px) {
    .gallery-section {
        padding-top: 70px;
        padding-bottom: 60px;

        .section-title {
            max-width: 100%; /* Đảm bảo tiêu đề co giãn trên tablet lớn */

            h2 {
                font-size: 55px; /* Kích thước lớn hơn cho tablet lớn */
            }

            p {
                font-size: 16px; /* Kích thước đoạn mô tả giữ nguyên */
            }
        }

        .gallery-grid {
            gap: 20px; /* Giữ khoảng cách chuẩn giữa các hình */
        }
    }
}
