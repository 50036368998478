.about-section {
    height: auto;
    padding: 100px 20px;
    text-align: center;

    .about-banner-text {
        h2 {
            font-size: 50px;
            font-weight: 700;
            margin-bottom: 20px;
        }

        p {
            font-size: 18px;
            line-height: 1.6;
            margin-bottom: 30px;
            color: #5A5A5A;
        }

        .theme-btn {
            margin-bottom: 30px; /* Thêm khoảng cách giữa button và hình ảnh */
        }
    }

    .about-banner-img {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        flex-wrap: wrap;

        img {
            max-width: 240px; /* Độ rộng tối đa */
            width: 100%; /* Đảm bảo hình ảnh co giãn */
            height: auto;
            border-radius: 20px;
            object-fit: cover;
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
            transition: transform 0.3s ease;

            &:hover {
                transform: scale(1.05);
            }

            &.pattern {
                display: none; /* Ẩn hình chấm bi trên Mobile */
            }
        }
    }
}

/* Mobile Responsive */
@media (max-width: 575px) {
    .about-section {
        padding: 120px 15px;  // Tăng padding top để tạo không gian cho tiêu đề

        .about-banner-text {
            margin-top: 20px;  // Thêm khoảng cách trên cho title để tránh bị che mất
            h2 {
                font-size: 36px;
                margin-bottom: 15px;
            }

            p {
                font-size: 16px;
                margin-bottom: 20px;
            }

            .theme-btn {
                margin-bottom: 40px; /* Tăng khoảng cách giữa button và hình ảnh */
            }
        }

        .about-banner-img {
            flex-direction: column;
            gap: 20px;

            img {
                max-width: 280px; /* Tăng độ rộng trên Mobile */
                margin: 0 auto;

                &.pattern {
                    display: none; /* Ẩn hình chấm bi */
                }
            }
        }
    }
}

/* Small Tablet Responsive */
@media (min-width: 576px) and (max-width: 767px) {
    .about-section {
        padding-top: 90px;
        padding-bottom: 40px;

        .about-banner-text {
            h2 {
                font-size: 38px;
                margin-bottom: 15px;
            }

            p {
                font-size: 17px;
                margin-bottom: 25px;
            }

            .theme-btn {
                margin-bottom: 50px; /* Khoảng cách giữa button và hình */
            }
        }

        .about-banner-img {
            flex-direction: column;
            gap: 25px;

            img {
                max-width: 300px; /* Tăng độ rộng cho tablet nhỏ */
                margin: 0 auto;

                &.pattern {
                    display: none; /* Ẩn hình chấm bi */
                }
            }
        }
    }
}

/* Medium Tablet Responsive */
@media (min-width: 768px) and (max-width: 991px) {
    .about-section {
        padding: 100px 30px;

        .about-banner-text {
            h2 {
                font-size: 42px;
                margin-bottom: 20px;
            }

            p {
                font-size: 18px;
                margin-bottom: 25px;
            }

            .theme-btn {
                margin-bottom: 60px; /* Khoảng cách lớn hơn trên tablet */
            }
        }

        .about-banner-img {
            flex-direction: row;
            gap: 20px;
            flex-wrap: wrap;

            img {
                max-width: 320px; /* Độ rộng hợp lý cho tablet lớn */
                margin: 0 auto;

                &.pattern {
                    display: block; /* Hiển thị hình chấm bi */
                }
            }
        }
    }
}
