.priority-section {
    padding-bottom: 150px;
    
    .priority-img {
        text-align: right;
        margin-right: 60px;
        position: relative;
        z-index: 1;
        margin-bottom: 30px;

        img {
            width: 70%; /* Thu nhỏ thêm 10% */
            height: auto;
            border-radius: 15px; /* Bo góc tròn */
            object-fit: cover; /* Đảm bảo hình ảnh vừa khung */
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Thêm hiệu ứng đổ bóng */
        }

        &::before {
            position: absolute;
            content: "";
            width: 40%; /* Thu nhỏ hiệu ứng nền */
            height: 60%; /* Thu nhỏ chiều cao nền */
            top: -10%; /* Điều chỉnh vị trí */
            left: 30%;
            background: #DBEFFA;
            z-index: -1;
            border-radius: 15px;
        }
    }

    .priority-text {
        max-width: 700px;
        margin-bottom: 30px;

        .section-title {
            margin-bottom: 30px;

            h2 {
                max-width: inherit;
            }
        }

        .theme-btn {
            margin-top: 80px;
        }
    }
}

/* Responsive cho Mobile */
@media (max-width: 575px) {
    .priority-section {
        padding-bottom: 20px;

        .priority-img {
            margin-right: 0;
            text-align: center;

            img {
                width: 90%; /* Thu nhỏ thêm trên mobile */
                border-radius: 15px; /* Bo góc tròn hơn */
            }

            &::before {
                display: none; /* Ẩn hiệu ứng nền nếu cần */
            }
        }

        .priority-text {
            .theme-btn {
                margin-top: 20px;
            }
        }
    }
}
