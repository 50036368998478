// src/sass/colors.scss

$color-blue: #583FBC;
$color-black: #181945;
$color-white: #ffffff;
$color-gray: #74758F;

// Thêm các biến màu mới
$color-dark: #333333; // Màu tối cho tiêu đề và các phần tử quan trọng
$color-text: #666666; // Màu cho văn bản thông thường
$color-gray-light: #f0f0f0; // Màu nền nhẹ cho blockquote
$color-gray-dark: #555555; // Màu xám trung bình cho blockquote
